import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Cookies from 'js-cookie'
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/AuthContext';
import { PlayerProfileProvider } from './context/PlayerProfileContext';
import { SocketProvider } from './context/SocketContext';
import { ScoreCardProvider } from './context/ScoreCardContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
const user_id = Cookies.get('user_id');
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/sw.js')
    .then((registration) => {
      console.log('Service Worker registered');
    })
    .catch((err) => {
      console.log('Service Worker registration failed:', err);
    });
}
root.render(
  // <React.StrictMode>
  <AuthProvider>
    <PlayerProfileProvider>
      <SocketProvider userId={user_id}>
        <ScoreCardProvider>
          <BrowserRouter>
            <App userId={user_id}/>
          </BrowserRouter>
        </ScoreCardProvider>
      </SocketProvider>
    </PlayerProfileProvider>
  </AuthProvider>
  // </React.StrictMode>
);

reportWebVitals();
