import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import Cookies from 'js-cookie';
import axiosinstance from '../../../axios/axiosInstance';
import FriendList from '../FindFriend/FriendList';
import LoaderProfile from '../Chat/LoaderProfile';

const ChallengeAFriend = () => {
  const user_id = Cookies.get('user_id');
  const [isLoading, setIsLoading] = useState(false);
  const [buttonClicked, setIsButtonClicked] = useState(false);
  const [friendList, setfriendslist] = useState();
  const [challengeData, setChallengeData] = useState();
  const [selectfriend, setSelectfriend] = useState();
  const loaderArray = [1,2,3];

  const handlechallengeData = (playerId, challenge_detail) => {
    setSelectfriend(playerId);
    setChallengeData(prevData => ({
      ...prevData,
      challenge_detail // Store data per playerId
    }));
  };

  const submitchallenge = () => {
    axiosinstance.post("/challenge/individual_challenge", { user_id, selectfriend, challengeData }, {
      headers: {
        authorization: Cookies.get("uid")
      }
    }).then((response) => {
      setIsLoading(false)
      toast(response.data.message, {
        className: "custom-success-toast"
      });
      // Close the modal properly
      const modal = document.getElementById("exampleModal");
      modal.classList.remove("show");
      modal.setAttribute("aria-hidden", "true");
      modal.style.display = "none";
      // Remove modal backdrop
      document.querySelectorAll(".modal-backdrop").forEach(el => el.remove());
      // Restore scrolling
      document.body.classList.remove("modal-open");
      document.body.style.overflow = "";
      document.body.style.paddingRight = "";
    }).catch((err) => {
      setIsLoading(false)
      toast('Internal Error')
      console.log(err);
    });
  }




  useEffect(() => {
    setIsLoading(true);
    axiosinstance.post("/friends/list", { user_id: user_id }, {
      headers: {
        authorization: Cookies.get("uid")
      }
    }).then((response) => {
      setIsLoading(false)
      setfriendslist(Object.values(response.data.result));
    }).catch((err) => {
      setIsLoading(false)
      toast('Error Getting Friendlist')
    });
  }, []);

  return (
    <div className='container mt-3'>
      <ToastContainer />
      <div className='row'>
        <h3 className='text-center playfair-display-sc-bold'>🔥 Face the Fire: One-on-One Cricket Battle!</h3>
        <p className='text-center'>
          It’s time to prove your skills in the ultimate one-on-one showdown! Challenge a rival, test your cricketing mastery, and dominate the pitch. Every ball, every shot, every moment counts<br />are you ready to rise to the challenge? 🏏🔥
        </p>
      </div>
      <div className='row'>
        <div className='col-md-7'>
          <div className="input-group my-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search a Friend"
              aria-label="searched Friend"
              autoComplete='off'
              aria-describedby="basic-addon2"
              style={{ borderRadius: "10px 0px 0px 10px" }}
              name='searchvalue'
            />
            <span className="input-group-text" id="basic-addon2" style={{ borderRadius: "0px 10px 10px 0px" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
              </svg>
            </span>
          </div>
        </div>
        <div className='col-md-5'>
          <div className='row my-3'>
            <h4 className='text-center'>YOUR FRIENDS</h4>
            <div>
              <div className='chat-list mt-3 no-scrollbar d-flex flex-column' style={{ minHeight: "30vh", maxHeight: "60vh", overflowY: "auto" }}>
                {
                  isLoading ?
                    <>
                      <div className='chat-list mt-3 no-scrollbar d-flex flex-column' style={{ minHeight: "30vh", maxHeight: "60vh", overflowY: "auto" }}>
                        {
                          loaderArray.map((item, key) => {
                            return (
                              <div key={item}>
                                <LoaderProfile />
                              </div>
                            )
                          })
                        }
                      </div>
                    </>
                    :
                    <>
                      {
                        friendList?.map((data, index) => (
                          <FriendList name={data.name} playerId={data.id} index={index} key={data.id} playingStyle={"Click to view profile"} imageUrl={data.profilePicture} buttonData='Challenge' setIsButtonClicked={setIsButtonClicked} onValuesChange={handlechallengeData} onsubmitchallenge={submitchallenge} />
                        ))
                      }
                    </>
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChallengeAFriend
