import React from 'react'

const ProfilePhoto = ({source, className, alt="Profile", style, name, onClick, page }) => {
    console.log("This is what i am getting in ProfilePicture Element ", source);
    const getInitials = (name) => {
        if (!name) return "";
        // Remove extra spaces and split into words
        const words = name.trim().split(" ").filter(word => word?.length > 0);
        if (words?.length === 0) return "";
        // If there is only one word, return its first letter
        if (words?.length === 1) {
            return words[0].charAt(0).toUpperCase();
        }
        // If there are multiple words, return the first letters of the first and last words
        return (words[0].charAt(0) + words[words?.length - 1].charAt(0)).toUpperCase();
    };
    

    const createImageFromInitials = (size, name) => {
        if (!name) return;
        name = getInitials(name); // Convert name to initials
    
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = canvas.height = size;
    
        // Set background to black
        context.fillStyle = "#000000"; 
        context.fillRect(0, 0, size, size);
    
        // Set text properties
        context.fillStyle = "#ffffff"; // White text color
        context.textBaseline = "middle";
        context.textAlign = "center";
        const fontSize = size * 0.4; // Adjust this multiplier for better scaling
        context.font = `bold ${fontSize}px Arial Black, Impact, sans-serif`;
        context.fillText(name, size / 2, size / 2);
    
        return canvas.toDataURL();
    };
    

    const handleInitials = (e, user) => {
        let datalink = createImageFromInitials(500, user);
        e.target.setAttribute("src", datalink);
    
        // Set a white border
        e.target.style.setProperty('border-color', '#ffffff', 'important');
        e.target.style.setProperty('border-width', '3px', 'important');
        if(page != "Chat"){
          e.target.style.setProperty('border-style', 'solid', 'important');
        }
        e.target.style.setProperty('border-radius', '50%', 'important');
    };
  return (
    <img 
      src={source || 'https://github.c/scado.png'} 
      className={className} 
      alt={alt} 
      style={style} 
      onClick={onClick}
      onError={(e) => {
        e.target.onerror = null; // Prevent infinite loop if initials also fail
        handleInitials(e, name);
      }}
    />
  )
}

export default ProfilePhoto
