import React, { useState, useEffect, useContext } from 'react'
import io from 'socket.io-client';
import axiosinstance from '../axios/axiosInstance';
import Cookies from 'js-cookie'
import { Link, Route, Routes, useLocation, useParams } from 'react-router-dom';
import About from './dashboard/UserProfile/About/About';
import Stats from './dashboard/UserProfile/Stats/Stats';
import { SocketContext } from '../context/SocketContext';
import { toast, ToastContainer } from 'react-toastify';
import ProfilePhoto from './ProfilePhoto';


const PlayerProfile = () => {
    const [showModal, setShowModal] = useState(false);
    const [userData, setUserData] = useState();
    const location = useLocation();
    const { id } = useParams();
    const curent_user = Cookies.get('user_id');
    const socket = useContext(SocketContext);

    useEffect(() => {
        const value = { user_id: id, curent_user: curent_user };
        const fetchData = async () => {
            await axiosinstance.post("/user/profile", value, {
                headers: {
                    'Authorization': Cookies.get('uid')
                }
            }).then((res) => {
                if (res.status === 201) {
                    setUserData(res.data[0]);
                }
                else {
                    console.log("Error while fetching the response ");
                }
            }).catch((err) => {
                console.log(err);
            });
        }
        fetchData();
    }, [id]);
    const sendFriendRequest = () => {
        socket.emit('sendFriendRequest', {
            sender_id: curent_user,
            receiver_id: id
        }, (res) => {
            if (res.err) {
                toast(res.message)
            }
            else {
                toast(res.message, {
                    className: "custom-success-toast"
                })
            }
        });
    };

    const handleImageClick = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };
        return (
        <div className='container-fluid'>
            <ToastContainer />

            {/* This is background image  */}
            <div className='header-image-container'>
                <div className="header-image headerimage" ></div>
            </div>

            {/* This is for profile picture section */}
            <div className="text-center" style={{ marginTop: "-60px", position: "relative" }}>
                <ProfilePhoto source="userData?.profilePicture" className="rounded-circle mt-n5" alt="Profile" style={{ width: "100px", height: "100px", border: "5px solid white" }} name={userData?.name} onClick={handleImageClick} />
                <div className='row align-items-center'>
                    <h2 className="mt-3 text-center">{userData ? userData.name : "Captain Name"} <button className='btn btn-dark float-right' onClick={sendFriendRequest}>Connect</button></h2>
                    <p className="text-center">{
                        userData ? <>{
                            userData.instagram_links && userData.facebook_links ?
                                <div className='d-flex justify-content-center'>
                                    <div className='mx-2'>
                                        <Link to={userData.instagram_links}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16">
                                                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                                            </svg>
                                        </Link>
                                    </div>
                                    <div className='mx-2'><Link to={userData.facebook_links}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                                        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                                    </svg></Link>
                                    </div>
                                </div>
                                : userData.city + userData.state}</> : 'CITY, INDIA'}
                    </p>
                </div>


                {/* Bootstrap Modal for zooming profile picture*/}
                <div className={`modal fade ${showModal ? 'show' : ''}`} tabIndex="-1" style={{ display: showModal ? 'block' : 'none' }} onClick={closeModal}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Profile Picture</h5>
                                <button type="button" className="btn-close" onClick={closeModal}></button>
                            </div>
                            <div className="modal-body">
                                <img
                                    src={userData ? userData.profilePicture : "https://github.com/mdo.png"}
                                    className="img-fluid"
                                    alt="Profile"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* This is the Ministat  */}
            <div className='container'>
                <div className="text-center mt-3 d-flex justify-content-center">
                    <div className="row text-white bg-dark p-3 rounded-pill mini-stat">
                        <div className="col-4">
                            <h5>0</h5>
                            <h6>Matches</h6>
                        </div>
                        <div className="col-4">
                            <h5>0</h5>
                            <h6>Runs</h6>
                        </div>
                        <div className="col-4">
                            <h5>0</h5>
                            <h6>Wicket</h6>
                        </div>
                    </div>
                </div>

                {/* This is Abot and Stats Button  */}
                <div className='row justify-content-center mt-5'>
                    <div className='col-auto'>
                        <ul className="nav nav-underline">
                            <li className="nav-item mx-2">
                                <Link className={location.pathname.includes('/about') ? "nav-link active" : 'nav-link'} to="about">About</Link>
                            </li>
                            <li className="nav-item mx-2">
                                <Link className={location.pathname.includes('/stats') ? "nav-link active" : 'nav-link'} to="stats">Stats</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <hr />
                <Routes>
                    <Route path="about" element={<About userData={userData} />} />
                    <Route path="stats" element={<Stats />} />
                </Routes>
            </div>
        </div>
    )
}

export default PlayerProfile
