import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import ChallengeATeam from './ChallengeATeam'
import ChallengeAFriend from './ChallengeAFriend'
import axiosinstance from '../../../axios/axiosInstance'
import ChallengeCard from './ChallengeCard'

const Challenge = () => {
    const user_id = Cookies.get('user_id');
    const location = useLocation();
    const navigate = useNavigate();
    const [challengeDetails, setChallengeDetails] = useState()

    useEffect(() => {
        if (location.pathname == "challenge") {
            navigate("/challenge/challenge_a_team");
        }
        axiosinstance.post("/challenge/get_challenges", { user_id }, {
            headers: {
                authorization: Cookies.get("uid")
            }
        }).then(res => {
            console.log("this is response ", res);
            setChallengeDetails(res?.data?.challenge_id);
        }).catch(err => {
            console.log("Error getting challenge details")
        })
    }, [location.pathname, navigate]);
    console.log("This is challengeDetails", challengeDetails)
    return (
        <>
            <div className='container' style={{ height: "80vh" }}>
                <div className='row mt-5'>
                    <div className='col-md-8' style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <h2 className='mb-3 playfair-display-sc-bold'>Dare to Compete ?
                            Step Into the Cricket Arena!</h2>
                        <p style={{ textAlign: "center" }}>
                            Think you’ve got what it takes to dominate the pitch? Whether it’s a one-on-one face-off or a full-fledged team battle, this is your chance to challenge rivals, prove your skills, and claim ultimate cricket glory!
                        </p>
                        <p style={{ textAlign: "center" }}>
                            Create a challenge, accept one, and let the game begin! Step into the Cricket Arena, where legends are made, and bragging rights are earned. Are you ready to take the challenge? 🏏🔥
                        </p>
                        <div>
                            <a href='#tab'>
                                <button className='btn btn-dark mx-2'>
                                    Challenge Now
                                </button>
                            </a>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <img src='/Image/Challenge.png' alt='cricket-image' className='img-fluid' style={{ borderRadius: "30% 70% 70% 30% / 30% 30% 70% 70% ", boxShadow: "0px 0px 9px 3px grey" }} />
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <h2 className='my-3 playfair-display-sc-bold' >Your Active challenges</h2>
                    <div
                        className="container no-scrollbar d-flex"
                        style={{
                            width: "100%",
                            overflowX: "auto",   // Enables horizontal scrolling
                            display: "flex",
                            flexWrap: "nowrap",  // Prevents wrapping
                            gap: "10px",         // Adds spacing between cards
                            padding: "10px 0"    // Adds some padding for aesthetics
                        }}
                    >
                        {challengeDetails?.map((challenge, key) => (
                            <div key={key} style={{ flex: "0 0 auto" }}> {/* Ensures each card stays inline */}
                                <ChallengeCard challenge = {challenge} />
                            </div>
                        ))}
                    </div>


                </div>
            </div>
            <div className='container'>
                <div className='row justify-content-center mt-5' id='tab'>
                    <div className='col-auto'>
                        <ul className="nav nav-underline">
                            <li className="nav-item">
                                <Link className={location.pathname.includes('/challenge_a_team') ? "nav-link active" : 'nav-link'} aria-current="page" to="challenge_a_team">Challenge a Team</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={location.pathname.includes('/challenge_a_friend') ? "nav-link active" : 'nav-link'} to="challenge_a_friend">Challenge a Friend</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <hr />

            <Routes>
                <Route path='challenge_a_team' element={<ChallengeATeam />} />
                <Route path='challenge_a_friend' element={<ChallengeAFriend />} />
            </Routes>
        </>
    )
}

export default Challenge
