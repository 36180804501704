import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './FriendList.module.css';
import { PlayerProfileContext } from '../../../context/PlayerProfileContext';
import ProfilePhoto from '../../ProfilePhoto';

const FriendList = ({ name, playingStyle, imageUrl, index, playerId, buttonData = "connect", setIsButtonClicked,onValuesChange ,onsubmitchallenge }) => {
    const {playerProfileData} = useContext(PlayerProfileContext)
    const [values, setValues] = useState({});

    // Determine background color based on index
   
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(new Date());

    const handleChange = (e) => {
        const newValues = {
            ...values,
            [e.target.name]: e.target.value
        };
        setValues(newValues);
        // Pass updated values to parent
        if (onValuesChange) {
            onValuesChange(playerId, newValues);
        }
    };
    
    

    const handleClick = () => {
        navigate(`/playerprofile/${playerId}/about`, { state: { playerId: playerId } });
    }
    const handleClickButton = () => {
        console.log("clicked");
        buttonData === "Challenge" && setIsButtonClicked(true);
    }
    return (
        <>
            <div
                className={`d-flex align-items-center py-2 px-3 my-2 friendListItem ${styles.friendListItem}`}
                style={{ backgroundColor: index % 2 === 0 ? 'rgb(244 240 240)' : 'rgb(239 239 239)' }}
            >
                <div className='me-3'>
                    <ProfilePhoto source={imageUrl} className='rounded-circle'
                        alt={name}
                        style={{ width: "50px", height: "50px", objectFit: "cover" }}
                        onClick={handleClick} name={name} page="Chat" />
                </div>
                <div className='flex-grow-1'>
                    <h6 className='mb-0' style={{ fontWeight: "600", color: "#050505" }} onClick={handleClick}>
                        {name || 'Friend Name'}
                    </h6>
                    <p className='mb-0' style={{ fontSize: "13px", color: "#65676B" }}>
                        {playingStyle || 'Playing style'}
                    </p>
                </div>
                <div>
                    {
                        buttonData === "Challenge" ?
                            <button type='button' className='btn btn-light btn-sm' style={{ border: "1px solid #ddd", fontWeight: "500" }} onClick={handleClickButton} data-bs-toggle="modal" data-bs-target="#exampleModal">
                                {buttonData}
                            </button>
                            :
                            <button type='button' className='btn btn-light btn-sm' style={{ border: "1px solid #ddd", fontWeight: "500" }} onClick={handleClickButton}>
                                {buttonData}
                            </button>
                    }
                </div>
            </div>

            {/* Modal for challenge */}
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Challenge a Friend</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className='d-flex justify-content-center align-items-center'>
                                <img src={imageUrl} alt="TeamA" width="50" height="50" className="rounded-circle mx-2 border" style={{ boxShadow: "0px 0px 4px 2px grey" }} />
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-lightning-fill" viewBox="0 0 16 16">
                                    <path d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641z" />
                                </svg>
                                <img src={playerProfileData.profilePicture || "https://github.com/mdo.png"} alt="Team" width="50" height="50" style={{ boxShadow: "0px 0px 4px 2px grey" }} className="rounded-circle mx-2" />
                            </div>
                            <div className='container'>
                                <div className='row mt-2'>
                                    <div className='col-md-6 col-6'>
                                        <h6>
                                            No. of Overs
                                        </h6>
                                        <input type="number" className="form-control" id="playerWeight" name='overs' placeholder='Number of overs' onChange={handleChange}></input>
                                    </div>
                                    <div className='col-md-6 col-6'>
                                        <h6>Types of Pitch</h6>
                                        <select className="form-select" aria-label="Default select example" name='pitch' onChange={handleChange}>
                                            <option value="">Select Pitch</option>
                                            <option value="Stadium" >Stadium</option>
                                            <option value="Turf">Turf</option>
                                            <option value="Cemented">Cemented</option>
                                            <option value="Green Pitch">Green Pitch</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-md-6 col-6'>
                                        <h6>
                                            Date of Match
                                        </h6>
                                        <input
                                            type="date"
                                            id="date"
                                            name="match_date"
                                            // onChange={(e) => setStartDate(e.target.value)}
                                            onChange={handleChange}
                                            className="date-input"
                                        />
                                    </div>
                                    <div className='col-md-6 col-6'>
                                        <h6>
                                            Time of Match
                                        </h6>
                                        <input
                                            id="timeInput"
                                            type="time"
                                            name="time"
                                            className="form-control"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-md-12 col-12'>
                                        <h6>Location</h6>
                                        <div className="form-floating">
                                            <textarea name='location' className="form-control" placeholder="Please Enter Location of match" id="floatingTextarea2" style={{height: "100px"}} onChange={handleChange}></textarea>
                                            <label htmlFor="floatingTextarea2">Please Enter Location of match</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className='d-flex align-items-center justify-content-end'>
                                <h6 className='mx-2'>Challenge Coins</h6>
                                <div className="input-group" style={{ width: "30%" }}>
                                    <input type="number" className="form-control" placeholder="Coins" aria-label="Recipient's username" aria-describedby="basic-addon2" name="coin" onChange={handleChange}/>
                                    <span className="input-group-text" id="basic-addon2" style={{ padding: "5px" }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#FFD700" className="bi bi-coin mx-1" viewBox="0 0 16 16">
                                        <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518z" />
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                        <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11m0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12" />
                                    </svg></span>
                                </div>
                                <button type="button" className="btn btn-primary mx-2" onClick={onsubmitchallenge}>Challenge</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FriendList;
