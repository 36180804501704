import React from 'react';
import './UserChatProfile.css'; // Importing custom CSS for styling
import { useNavigate } from 'react-router-dom';
import ProfilePhoto from '../../ProfilePhoto';

const UserChatProfile = ({ captain, name, lastMessage = "Hello", lastActive, profilepicture, room_id }) => {
    const navigate = useNavigate();
    const handleClick = ()=>{
        if(room_id){
            navigate(`/groupprofile/${room_id}/about`)
        }
    }
    return (
        <div className='user-chat-profile d-flex align-items-center my-2 p-2' onClick={room_id ? handleClick : undefined}>
            <div className='profile-picture-container me-3'>
                <ProfilePhoto className='profile-picture' alt='Profile' source={profilepicture} name={name} page="Chat" />
                
            </div>
            <div className='profile-details flex-grow-1'>
                <h6 className='mb-1'>
                    <b>{name}</b> {captain && <span className="badge bg-success captain-badge">C</span>}
                </h6>
                <small className='text-muted last-message'>
                    {lastMessage}
                </small>
            </div>
            <div className='last-active-time text-muted'>
                <small>{lastActive}</small>
            </div>
        </div>
    );
}

export default UserChatProfile;
