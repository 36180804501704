import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import Cookies from 'js-cookie';
import axiosinstance from '../../../axios/axiosInstance';
import UserChatProfile from '../Chat/UserChatProfile';
import LoaderProfile from '../Chat/LoaderProfile';

const ChallengeATeam = () => {
  const user_id = { "user_id": Cookies.get('user_id') };
  const [teamsData, setTeamsdata] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const loaderArray = [1, 2, 3];
  useEffect(() => {
    setIsLoading(true);
    axiosinstance.post("/groups/groupslist", user_id, {
      headers: {
        authorization: Cookies.get("uid")
      }
    }).then((response) => {
      setIsLoading(false)
      setTeamsdata(Object.values(response.data.result));
    }).catch((err) => {
      setIsLoading(false)
      toast('Error Occured')
    });
  }, []);

  console.log("This is teamsDetails", teamsData);
  return (
    <div className='container mt-3'>
      <ToastContainer />
      <div className='row'>
        <h3 className='text-center playfair-display-sc-bold'>⚔️ Battle of the Best: Team Cricket Challenge!</h3>
        <p className='text-center'>
          Gather your squad and step onto the battlefield! Browse through a list of joined teams or search for the ultimate all-rounder squad to challenge. Only the strongest will prevail— <br />are you ready to make your mark on the pitch? 🏆🔥
        </p>
      </div>
      <div className='row'>
        <div className='col-md-7'>
          <div className="input-group my-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search a team"
              aria-label="searched team"
              autoComplete='off'
              aria-describedby="basic-addon2"
              style={{ borderRadius: "10px 0px 0px 10px" }}
              name='searchvalue'
            />
            <span className="input-group-text" id="basic-addon2" style={{ borderRadius: "0px 10px 10px 0px" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
              </svg>
            </span>
          </div>
        </div>
        <div className='col-md-5'>
          <div className='row my-3'>
            <h4 className='text-center'>YOUR TEAMS</h4>
            <div>
              <div className="row chat-list no-scrollbar" style={{ flex: "1 1 auto", overflowY: "auto", maxHeight: "60vh" }}>
                {
                  isLoading ?
                    <>
                      <div className='chat-list mt-3 no-scrollbar d-flex flex-column' style={{ minHeight: "30vh", maxHeight: "60vh", overflowY: "auto" }}>
                        {
                          loaderArray.map((item, key) => {
                            return (
                              <div key={item}>
                                <LoaderProfile />
                              </div>
                            )
                          })
                        }
                      </div>
                    </>
                    :
                    <>
                      {teamsData?.slice().map((data, index) => (
                        <div key={index}>
                          <UserChatProfile name={data.team_name} room_id={data.room_id} lastMessage={"View More on clicking it"} />
                        </div>
                      ))}
                    </>
                }
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  )
}

export default ChallengeATeam
