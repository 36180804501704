import React, { useEffect, useState } from 'react'
import FriendList from './dashboard/FindFriend/FriendList';
import TeamMember from './TeamMember';
import axiosinstance from '../axios/axiosInstance';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import LoaderProfile from './dashboard/Chat/LoaderProfile';

const GroupProfile = () => {
    const { roomid } = useParams();
    const [showModal, setShowModal] = useState(false);
    const [buttonClicked, setIsButtonClicked] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [groupDetail, setGroupDetail] = useState();
    const [groupAdminDetail, setGroupAdminDetail] = useState();
    const [groupTeamDetail, setGroupTeamDetail] = useState();
    const listOFMember = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const loaderArray = [1, 2, 3];


    const handleImageClick = () => {
        setShowModal(true);
    };
    useEffect(() => {
        setIsLoading(true);
        axiosinstance.post("/room/room_details", { room_id: roomid }, {
            headers: {
                authorization: Cookies.get("uid")
            }
        }).then((response) => {
            setIsLoading(false)
            console.log(response.data);
            setGroupDetail(response.data);
            setGroupAdminDetail(response?.data?.owner);
            setGroupTeamDetail(response?.data?.member);
        }).catch((err) => {
            setIsLoading(false)
            toast('Error Getting Group Details')
            console.log(err);
        });
    }, []);
    console.log("this is team details ", groupTeamDetail)

    const closeModal = () => {
        setShowModal(false);
    };
    return (
        <div>
            <div className='header-image-container'>
                <div className="header-image headerimage" ></div>
            </div>

            <div className="container text-center" style={{ marginTop: "-60px", position: "relative" }}>
                <img
                    src={"https://github.com/mdo.png"}
                    className="rounded-circle mt-n5"
                    alt="Profile"
                    style={{ width: "100px", height: "100px", border: "5px solid white" }}
                    onClick={handleImageClick}
                />

                <div className='row align-items-center'>
                    <h2 className="mt-3 text-center">{
                        groupAdminDetail ? groupAdminDetail[0]?.team_name : "Team Name"
                    }</h2>
                    <p>{groupAdminDetail ? groupAdminDetail[0]?.group_description || "Group Description" : " Group Description"}</p>
                </div>

                <div className='container'>
                    <div className='row'>
                        <div className='col-md-8'>
                            <div className='row mt-2'>
                                <div className='col-md-6' style={{ textAlign: "left" }}>
                                    <h3>Team Member</h3>
                                </div>
                                <div className='col-md-6' style={{ textAlign: "right" }}>
                                    <button className='btn btn-dark mx-2'>
                                        Add Member
                                    </button>
                                    <button className='btn btn-danger mx-2'>
                                        Leave Team
                                    </button>
                                </div>
                            </div>
                            <div className='row no-scrollbar mt-2' style={{ maxHeight: "80vh", overflowY: "auto" }}>
                                <div className='col-md-12'>
                                    {
                                        isLoading ?
                                            <>
                                                <div className='chat-list mt-3 no-scrollbar d-flex flex-column' style={{ minHeight: "30vh", maxHeight: "60vh", overflowY: "auto" }}>
                                                    {
                                                        loaderArray.map((item, key) => {
                                                            return (
                                                                <div key={item}>
                                                                    <LoaderProfile />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </>
                                            :
                                            <>
                                                {
                                                    groupAdminDetail?.map((item, key) => (
                                                        <TeamMember name={item?.name} playerId={item.friend_id} index={key} key={key} playingStyle={"playerStyle"} imageUrl={item?.profilePicture || "https://i.ibb.co/tMDN1vK/cricket-team.jpg"} setIsButtonClicked={setIsButtonClicked} />
                                                    ))
                                                }
                                                {
                                                    groupTeamDetail?.map((item, key) => (
                                                        <TeamMember name={item?.name} playerId={item.friend_id} index={key} key={key} playingStyle={"playerStyle"} imageUrl={item?.profilePicture || "https://i.ibb.co/tMDN1vK/cricket-team.jpg"} setIsButtonClicked={setIsButtonClicked} />
                                                    ))
                                                }
                                            </>
                                    }

                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='row mt-2' style={{ textAlign: "center" }}>
                                <h3>Recent Matches</h3>
                            </div>
                            <div className='row no-scrollbar mt-2 justify-content-center' style={{ maxHeight: "80vh", overflow: "auto" }}>
                                {
                                    listOFMember.map((item, key) => (
                                        <div className="card mx-2 my-2" style={{ width: "80%", height: "120px" }}>
                                            <div className="card-body">
                                                <h6 className="card-title text-center">
                                                    <img src={"https://i.ibb.co/tMDN1vK/cricket-team.jpg"} alt="TeamA" width="50" height="50" className="rounded-circle mx-2 border" style={{ boxShadow: "0px 0px 4px 2px grey" }} />
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-lightning-fill" viewBox="0 0 16 16">
                                                        <path d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641z" />
                                                    </svg>
                                                    <img src={"https://github.com/mdo.png"} alt="Team" width="50" height="50" style={{ boxShadow: "0px 0px 4px 2px grey" }} className="rounded-circle mx-2" />
                                                </h6>
                                                <p className="card-text">
                                                    <strong>
                                                        Falane Team won
                                                    </strong>
                                                </p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                    </div>
                </div>



                {/* Bootstrap Modal for zooming profile picture*/}
                <div className={`modal fade ${showModal ? 'show' : ''}`} tabIndex="-1" style={{ display: showModal ? 'block' : 'none' }} onClick={closeModal}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Profile Picture</h5>
                                <button type="button" className="btn-close" onClick={closeModal}></button>
                            </div>
                            <div className="modal-body">
                                <img
                                    src={"https://github.com/mdo.png"}
                                    className="img-fluid"
                                    alt="Profile"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GroupProfile
