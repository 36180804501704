import React, { useContext, useEffect, useState } from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import UserChatProfile from './UserChatProfile';
// import teamsData from './TeamData.json';
import axiosinstance from '../../../axios/axiosInstance'
import Cookies from 'js-cookie';
import Cropper from "react-easy-crop";
import { SocketContext } from '../../../context/SocketContext';
import { toast, ToastContainer } from 'react-toastify';
import LoaderProfile from './LoaderProfile';
import { getCroppedImg } from "../UserProfile/cropUtils";

const SideBar = ({ setSelectedFriend, setMessages }) => {
    // const user_id = Cookies.get("user_id");
    const socket = useContext(SocketContext);
    const location = useLocation();
    const [teamsData, setTeamsdata] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [friendslist, setfriendslist] = useState([]);
    const [isCreatingTeam, setIsCreatingTeam] = useState(false);
    const [teamName, setTeamName] = useState();
    const [teamDescription, setTeamDescription] = useState();
    const [selectedFriends, setSelectedFriends] = useState([]);
    const user_id = { "user_id": Cookies.get('user_id') };
    const [isLoading, setIsLoading] = useState(false);
    const loaderArray = [1, 2, 3];
    const [imageSrc, setImageSrc] = useState(null);
    const [cropModal, setCropModal] = useState(false);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedImage, setCroppedImage] = useState("https://github.com/mdo.png"); // Default profile image
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    useEffect(() => {
        if (!socket) {
            console.log("Socket is not initialized yet");
        }
        setIsLoading(true);
        axiosinstance.post("/friends/list", user_id, {
            headers: {
                authorization: Cookies.get("uid")
            }
        }).then((response) => {
            setIsLoading(false)
            setfriendslist(Object.values(response.data.result));
        }).catch((err) => {
            setIsLoading(false)
            toast('Error Getting Friendlist')
            console.log(err);
        });
    }, []);

    useEffect(() => {
        setIsLoading(true);
        axiosinstance.post("/groups/groupslist", user_id, {
            headers: {
                authorization: Cookies.get("uid")
            }
        }).then((response) => {
            setIsLoading(false)
            setTeamsdata(Object.values(response.data.result));
        }).catch((err) => {
            setIsLoading(false)
            toast('Error Occured')
            console.log(err);
        });
    }, [selectedFriends]);

    const filteredFriends = friendslist.filter(friend =>
        friend.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    filteredFriends.sort((a, b) => new Date(b.last_message_time) - new Date(a.last_message_time));
    const filteredTeamsData = teamsData.filter(team =>
        team.team_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    filteredTeamsData.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))

    const handleFriendClick = (friend) => {
        setMessages([]);
        setSelectedFriend(friend);
        if (!friend.room_id) {
            axiosinstance.post("/chats/getchats", { friend_id: friend.id, user_id: Cookies.get("user_id") }, {
                headers: {
                    "Content-Type": "application/json",
                    authorization: Cookies.get("uid")
                }
            }).then((response) => {
                const updatedFriend = { ...friend, chat: response.data };
                setSelectedFriend(updatedFriend);
                setMessages((prevMessages) => [...prevMessages, ...response.data]);
            }).catch((err) => {
                console.log(err);
            });
        } else {
            axiosinstance.post("/chats/roomchats", { room_id: friend.room_id, user_id: Cookies.get("user_id") }, {
                headers: {
                    authorization: Cookies.get("uid"),
                }
            }).then((response) => {
                console.log("this is response ", response);
                const updatedFriend = { ...friend, chat: response.data };
                setSelectedFriend(updatedFriend);
                setMessages((prevMessages) => [...prevMessages, ...response.data]);
            }).catch((err) => {
                console.log(err);
            });
        }
    };

    const handleCreateTeam = () => {
        setIsCreatingTeam(!isCreatingTeam);
    };

    const handleTeamNameChange = (e) => {
        setTeamName(e.target.value);
    };
    const handleTeamDescriptionChange = (e) => {
        setTeamDescription(e.target.value)
    }

    const handleFriendSelection = (friendId) => {
        setSelectedFriends(prevSelectedFriends =>
            prevSelectedFriends.includes(friendId)
                ? prevSelectedFriends.filter(id => id !== friendId)
                : [...prevSelectedFriends, friendId]
        );
    };

    const handleSubmitTeam = () => {
        if (!teamName && !selectedFriends.length) {
            toast("Fill All required field")
            return;
        }
        const newTeam = {
            name: teamName,
            members: selectedFriends,
            description: teamDescription,
        };
        console.log(newTeam);
        // Send request to create a room and invite friends
        socket.emit('createRoom', {
            teamName: newTeam.name,
            selectedFriends: newTeam.members,
            created_by: Cookies.get("user_id")
        }, (roomId) => {
            console.log('Room created with ID:', roomId);

            // Optionally, you can navigate to the room chat or update the UI
        });

        setIsCreatingTeam(false);
        setTeamName('');
        setTeamDescription('');
        setSelectedFriends([]);
    };
    const handleFileChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setImageSrc(event.target.result);
                setCropModal(true); // Open modal for cropping
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    };
    const handleCropComplete = (_, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };
    const saveCroppedImage = async () => {
        try {
            const { base64 } = await getCroppedImg(imageSrc, croppedAreaPixels);
            setCroppedImage(base64); // Set cropped image as profile picture
            setCropModal(false); // Close modal
        } catch (error) {
            console.error("Crop failed:", error);
        }
    };

    const lastActive = (lastActiveTime) => {
        const timestamp = new Date(lastActiveTime);
        const today = new Date();

        // Check if the date matches today's date
        const isToday =
            timestamp.getDate() === today.getDate() &&
            timestamp.getMonth() === today.getMonth() &&
            timestamp.getFullYear() === today.getFullYear();

        if (isToday) {
            // Show time if it falls today
            return timestamp.toLocaleTimeString('en-US', {
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });
        } else {
            // Show date otherwise
            return timestamp.toLocaleDateString('en-US', {
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            });
        }
    }


    return (
        <div className='vh-100 chat-sidebar'>
            <ToastContainer />
            <div className='container-fluid'>
                <div className='row'>
                    <h2>Cricket Chats</h2>
                    <small>Unite.Play.Conquer!</small>
                    <div className="input-group my-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Friend's username"
                            aria-label="Friend's username"
                            aria-describedby="basic-addon2"
                            style={{ borderRadius: "10px 0px 0px 10px" }}
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                        />
                        <span className="input-group-text" id="basic-addon2" style={{ borderRadius: "0px 10px 10px 0px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                            </svg>
                        </span>
                    </div>
                </div>
                <div className='row justify-content-center'>
                    <div className='col-auto'>
                        <ul className="nav nav-underline">
                            <li className="nav-item mx-2">
                                <Link className={location.pathname.includes("/friendschat") ? "nav-link active" : "nav-link"} to="friendschat" onClick={() => {
                                    setSelectedFriend(null);
                                }}>Friends</Link>
                            </li>
                            <li className="nav-item mx-2">
                                <Link className={location.pathname.includes("/teamschat") ? "nav-link active" : "nav-link"} to="teamschat" onClick={() => {
                                    setSelectedFriend(null);
                                }}>Teams</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <Routes>
                    <Route path='friendschat' element={
                        <>
                            {
                                isLoading ?
                                    <>
                                        <div className='chat-list mt-3 no-scrollbar d-flex flex-column' style={{ minHeight: "30vh", maxHeight: "60vh", overflowY: "auto" }}>
                                            {
                                                loaderArray.map((item, key) => {
                                                    return (
                                                        <div key={item}>
                                                            <LoaderProfile />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </>
                                    :
                                    <div className='chat-list mt-3 no-scrollbar d-flex flex-column' style={{ minHeight: "30vh", maxHeight: "60vh", overflowY: "auto" }}>
                                        {
                                            filteredFriends.length > 0 ? (
                                                filteredFriends.map((data, index) => (
                                                    <div key={index} onClick={() => handleFriendClick(data)}>
                                                        <UserChatProfile
                                                            captain={data.captain}
                                                            name={data.name}
                                                            profilepicture={data.profilePicture}
                                                            lastMessage={data.last_message ? data.last_message : "Start a conversation"}
                                                            lastActive={lastActive(data.last_message_time)}
                                                        />
                                                    </div>
                                                ))
                                            ) : (
                                                <p>No friends available</p>
                                            )
                                        }
                                        {filteredFriends.length < 3 && <div className='flex-grow-1'></div>} {/* Pushes content to the top */}
                                    </div>
                            }
                        </>
                    } />
                    <Route path='teamschat' element={
                        <div className="team-container position-relative" style={{ minHeight: "70vh", display: "flex", flexDirection: "column" }}>
                            {/* Scrollable Content */}
                            {
                                isLoading ?
                                    <>
                                        <div className='chat-list mt-3 no-scrollbar d-flex flex-column' style={{ minHeight: "30vh", maxHeight: "60vh", overflowY: "auto" }}>
                                            {
                                                loaderArray.map((item, key) => {
                                                    return (
                                                        <div key={item}>
                                                            <LoaderProfile />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </>
                                    :
                                    <div
                                        className="row chat-list no-scrollbar"
                                        style={{ flex: "1 1 auto", overflowY: "auto", maxHeight: "60vh", minHeight: "60vh", flexDirection: "column", alignItems: "flex-start", flexWrap:"nowrap" }}
                                    >
                                        {filteredTeamsData.slice().reverse().map((data, index) => (
                                            <div key={index} onClick={() => handleFriendClick(data)}>
                                                <UserChatProfile name={data.team_name} lastMessage={data.messageText ? data.messageText : "Start a conversation"} lastActive={lastActive(data.timestamp)} />
                                            </div>
                                        ))}
                                    </div>
                            }

                            {/* Modal for creating team  */}
                            <div className="modal fade" id="createTeamModal" tabindex="-1" aria-labelledby="createTeamModalLabel" aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h1 className="modal-title fs-5" id="createTeamModalLabel">Create Team</h1>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            <div className='container'>
                                                <div className='row my-2'>
                                                    <div className='col-md-12 col-12'>
                                                        <div className="d-flex justify-content-center align-items-center">
                                                            <label htmlFor="teamImageUpload" className="position-relative">
                                                                <img
                                                                    src={croppedImage}
                                                                    alt="TeamA"
                                                                    width="70"
                                                                    height="70"
                                                                    className="rounded-circle mx-2 border"
                                                                    style={{ boxShadow: "0px 0px 4px 2px grey", cursor: "pointer" }}
                                                                />
                                                            </label>
                                                            <input
                                                                type="file"
                                                                id="teamImageUpload"
                                                                accept="image/*"
                                                                style={{ display: "none" }}
                                                                onChange={handleFileChange}
                                                            />
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className='row'>
                                                            <div className='col-md-12'>
                                                                <label className="form-label">
                                                                    Enter Team Name
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control mb-3"
                                                                    placeholder="Enter Team Name"
                                                                    value={teamName}
                                                                    onChange={handleTeamNameChange}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-md-12'>
                                                                <label className="form-label">
                                                                    Enter Team Description
                                                                </label>
                                                                <textarea class="form-control" placeholder="Enter Team Description" id="floatingTextarea" onChange={handleTeamDescriptionChange}></textarea>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label className="form-label">
                                                            Select Team Members
                                                        </label>
                                                        <div className="friends-list no-scrollbar" style={{ maxHeight: "300px", overflowY: "auto", }}>
                                                            {filteredFriends.map(friend => (
                                                                <div
                                                                    key={friend.id}
                                                                    className={`user-chat-profile d-flex align-items-center mb-2 p-2 ${selectedFriends.includes(friend.id) ? 'selected' : ''}`}
                                                                    onClick={() => handleFriendSelection(friend.id)}
                                                                    style={{ cursor: "pointer", border: selectedFriends.includes(friend.id) ? "2px solid #0d6efd" : "1px solid #ccc", borderRadius: "8px" }}
                                                                >
                                                                    <div className='me-2'>
                                                                        <img
                                                                            src={friend.profilePicture || "https://github.com/mdo.png"}
                                                                            className='profile-picture'
                                                                            alt='Profile'
                                                                            style={{ width: "30px", height: "30px", borderRadius: "50%" }}
                                                                        />
                                                                    </div>
                                                                    <div className='profile-details flex-grow-1'>
                                                                        <p style={{ margin: "0px" }}>
                                                                            <strong>{friend.name}</strong>
                                                                        </p>
                                                                    </div>
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input ms-2"
                                                                        checked={selectedFriends.includes(friend.id)}
                                                                        readOnly
                                                                        style={{ marginLeft: "auto" }}
                                                                    />
                                                                </div>
                                                            ))}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-dark" onClick={handleSubmitTeam}>Create Team</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Sticky Button */}
                            <button
                                className="btn btn-dark rounded-circle d-flex align-items-center justify-content-center sticky-button" data-bs-toggle="modal" data-bs-target="#createTeamModal"
                                onClick={handleCreateTeam}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                                </svg>
                            </button>

                            {/* Crop Modal  */}
                            {cropModal && (
                                <div className="modal fade show" tabIndex="-1" style={{ display: "block" }}>
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">Crop Profile Picture</h5>
                                                <button type="button" className="btn-close" onClick={() => setCropModal(false)}></button>
                                            </div>
                                            <div className="modal-body">
                                                <div style={{ position: "relative", width: "100%", height: "300px" }}>
                                                    <Cropper
                                                        image={imageSrc}
                                                        crop={crop}
                                                        zoom={zoom}
                                                        aspect={1}
                                                        onCropChange={setCrop}
                                                        onZoomChange={setZoom}
                                                        onCropComplete={handleCropComplete}
                                                    />
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-primary" onClick={saveCroppedImage}>
                                                    Save
                                                </button>
                                                <button type="button" className="btn btn-secondary" onClick={() => setCropModal(false)}>
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    } />
                </Routes>

            </div>
        </div>
    );
}

export default SideBar;
